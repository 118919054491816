import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  getTimeZoneString,
  getRelativeTimeString,
  toLocaleDayMonthYearString,
  toLocale12HourTimeString,
} from '../../../Functions';

import NoAppointment from '../../../assets/images/no-appointment.svg';
import GeneralListRow from '../GeneralList/GeneralListRow';
import RoomLinkIcon from '@material-ui/icons/Launch';
import GeneralList from '../GeneralList';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

import { api } from '../../../services/api';

import {
  AppointmentStatus,
  ConfirmationType,
  getStatusLabel,
} from './Functions';

const useStyles = makeStyles(() => ({
  secondaryText: {
    color: '#616161',
  },
}));

const StyledContainer = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ListItemContainer = styled.div`
  text-align: start;
`;

const DateTimeZoneLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  margin-top: 4px;
`;

const PersonNameLabel = styled(Typography)`
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
`;

const StatusFilterContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 18px;
  margin-bottom: 29px;
`;

const StatusFilterChip = styled(Chip)`
  min-width: 120px;
  height: 24px;
  color: ${(props) => (props.status === 'active' ? '#ffffff' : '#212121')};
  background: ${(props) => (props.status === 'active' ? '#202020' : '#F5F5F5')};

  &:active {
    box-shadow: unset;
  }

  ${(props) =>
    props.status === 'active'
      ? `&:hover, &:focus {
      background-color: #000000;
    }`
      : `&:focus {
        background-color: #F5F5F5;
      }
    `}
`;

const StatusActionButton = styled(Button)`
  margin-right: 8px;
  padding: 4px 10px;

  ${(props) =>
    props.status &&
    `
    background: ${
      props.status === 'confirm'
        ? 'rgba(55, 204, 132, 0.08)'
        : 'rgba(216, 12, 36, 0.08)'
    };

    &:hover {
      background: ${
        props.status === 'confirm'
          ? 'rgba(55, 204, 132, 0.18)'
          : 'rgba(216, 12, 36, 0.18)'
      };
    }
    `}
`;

const CancelActionButton = styled(Button)`
  margin-right: 8px;
  padding: 4px 10px;
  box-sizing: border-box;
  border: 1px solid rgba(216, 12, 36, 0.5);
  border-radius: 4px;
  color: #d80c24;

  &:hover {
    background-color: rgba(216, 12, 36, 0.08);
  }
`;

const StatusActionButtonLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 700;

  ${(props) =>
    props.status &&
    `
  color: ${props.status === 'confirm' ? '#37CC85' : '#D80C24'};
  `}
`;

const NoAppointmentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 260px;
`;

const StatusChip = styled(Chip)`
  font-size: 12px;
  line-height: 12px;
  width: 88px;
  height: 18px;
  margin-right: 24px;

  background: ${(props) => {
    switch (props.rawstatus) {
      case getStatusLabel(AppointmentStatus.CONFIRMED):
        return 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #37CC85;';

      case getStatusLabel(AppointmentStatus.PENDING):
        return 'rgba(32, 32, 32, 0.08)';

      case getStatusLabel(AppointmentStatus.CANCELLED):
      case getStatusLabel(AppointmentStatus.REJECTED):
        return 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C04151;';

      default:
        return 'unset';
    }
  }};

  color: ${(props) => {
    switch (props.rawstatus) {
      case getStatusLabel(AppointmentStatus.CONFIRMED):
        return '#37CC85';

      case getStatusLabel(AppointmentStatus.PENDING):
        return '#424242';

      case getStatusLabel(AppointmentStatus.CANCELLED):
      case getStatusLabel(AppointmentStatus.REJECTED):
        return '#D80C24';

      default:
        return 'unset';
    }
  }};
`;

const NoAppointmentLabel = styled(Typography)`
  color: #616161;
  margin-top: 24px;
`;

const DialogActionButton = styled(Button)`
  width: 89px;
  height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 12px;
`;

const DialogCancelButton = styled(DialogActionButton)`
  border: 1px solid rgba(0, 0, 0, 0.23);
`;

const DialogConfirmButton = styled(DialogActionButton)`
  background: #d80c24;
  color: #ffffff;
  margin-right: 12px;

  &:hover {
    background: #b8071c;
  }
`;

function List({ apps, CODE, REPS, lastUpdated, eventId }) {
  const { t } = useTranslation();
  const REP_ID = REPS[0].id;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [APP_ID, setAppId] = useState(null);
  const [confirmationType, setConfirmationType] = useState(null);
  const [isConfirmationLoading, setIsConfirmationLoading] = useState(false);
  const [currentLastUpdated, setCurrentLastUpdated] = useState(lastUpdated);

  const columns = useMemo(
    () => [
      {
        id: 'roomLink',
        label: t('roomLink'),
      },
      {
        id: 'status',
        label: t('status'),
      },
      {
        id: 'dateTime',
        label: t('dateTime'),
      },
      {
        id: 'createTime',
        label: t('createTime'),
      },
      {
        id: 'personToBeMet',
        label: t('personToBeMet'),
      },
      {
        id: 'action',
        label: '',
      },
    ],
    [t],
  );

  const parseAppointmentStatusesCount = useMemo(
    () => ({
      [getStatusLabel(AppointmentStatus.CONFIRMED)]: apps.filter(
        (it) => it.status === AppointmentStatus.CONFIRMED,
      ).length,
      [getStatusLabel(AppointmentStatus.PENDING)]: apps.filter((it) =>
        [AppointmentStatus.NORMAL, AppointmentStatus.PENDING].includes(
          it.status,
        ),
      ).length,
      [getStatusLabel(AppointmentStatus.REJECTED)]: apps.filter(
        (it) => it.status === AppointmentStatus.REJECTED,
      ).length,
      [getStatusLabel(AppointmentStatus.CANCELLED)]: apps.filter(
        (it) => it.status === AppointmentStatus.CANCELLED,
      ).length,
    }),
    [apps],
  );

  const handleAccept = useCallback(async (appId) => {
    setIsConfirmationLoading(true);

    try {
      await api.appointment.acceptAppointment({
        app_id: appId,
      });
      window.location.reload();
    } catch (e) {
      setIsConfirmationLoading(false);
      if (e.response.data) {
        const { message } = e.response.data;
        alert(`Failed to confirm appointment: ${message}`);
      }
    }
  }, []);

  const handleReject = useCallback(async () => {
    setIsConfirmationLoading(true);

    await api.appointment.rejectAppointment({
      app_id: APP_ID,
    });

    window.location.reload();
  }, [APP_ID]);

  const handleConfirmationDialogOpen = useCallback((id, type) => {
    setAppId(id);
    setConfirmationType(type);
    setOpen(true);
  }, []);

  const handleConfirmationDialogClose = useCallback(() => {
    setOpen(false);
  }, []);

  const isAttendeeOwnAppointment = useCallback(
    (appointment) => {
      return appointment.rep_id === REP_ID;
    },
    [REP_ID],
  );

  const handleConfirmationDialogConfirm = useCallback(() => {
    if (confirmationType === ConfirmationType.CANCEL) {
      const app = apps.find((ap) => ap.id === APP_ID);

      let initiator = app.attendee.email;

      if (isAttendeeOwnAppointment(app)) {
        initiator = app.rep_id;
      }

      window.location.href = `/cancel/${APP_ID}?initiator=${initiator}`;
    }

    if (confirmationType === ConfirmationType.REJECT) {
      handleReject();
    }

    handleConfirmationDialogClose();
  }, [
    APP_ID,
    apps,
    confirmationType,
    handleConfirmationDialogClose,
    handleReject,
    isAttendeeOwnAppointment,
  ]);

  const getTranslatedStatusLabel = useCallback(
    (status) => {
      switch (status) {
        case AppointmentStatus.NORMAL:
        case AppointmentStatus.PENDING:
          return t('statusPending');

        case AppointmentStatus.CONFIRMED:
          return t('statusConfirmed');

        case AppointmentStatus.REJECTED:
          return t('statusRejected');

        case AppointmentStatus.CANCELLED:
          return t('statusCancelled');

        default:
          return '';
      }
    },
    [t],
  );

  const parseAttendeeField = useCallback(
    (appointment, field) => {
      if (isAttendeeOwnAppointment(appointment) && appointment.invitee) {
        if (field === 'company') {
          return appointment.attendee[field];
        }

        return appointment.invitee[field];
      }

      if (!isAttendeeOwnAppointment(appointment) && appointment.inviter) {
        return appointment.inviter[field];
      }

      if (field === 'name') {
        return appointment.attendee.name === ''
          ? `${appointment.attendee.first} ${appointment.attendee.last}`
          : appointment.attendee.name;
      }

      return '';
    },
    [isAttendeeOwnAppointment],
  );

  const getAppointments = useCallback(
    () =>
      apps
        .map((appointment) => {
          return {
            id: appointment.id,
            rep_id: appointment.rep_id,
            dateTime: {
              date: toLocaleDayMonthYearString(appointment.start, CODE),
              time: `${toLocale12HourTimeString(
                appointment.start,
                CODE,
              )} - ${toLocale12HourTimeString(appointment.end, CODE)}`,
              zone: getTimeZoneString(),
            },
            createTime: getRelativeTimeString(appointment.createTime, CODE),
            personToBeMet: {
              name: parseAttendeeField(appointment, 'name'),
              company: parseAttendeeField(appointment, 'company'),
              title: parseAttendeeField(appointment, 'title'),
            },
            status: getStatusLabel(appointment.status),
            roomLink:
              eventId === 'Jp3xqJBO3YmpcUqZYtiO' ||
              eventId === 'FHSwsFN3or05ZZ9qTMOD'
                ? null
                : appointment.status === AppointmentStatus.CONFIRMED &&
                  (appointment.meetingLink ??
                    (appointment.inviter && appointment.inviter.link)),
            inviter: appointment.inviter,
            translatedStatus: getTranslatedStatusLabel(appointment.status),
            config: {
              isCancelDisabled: appointment?.isCancelDisabled ?? false,
            },
            rawCreateTime: appointment.createTime,
            rawStart: appointment.start,
            rawEnd: appointment.end,
          };
        })
        .sort((a, b) => b.rawCreateTime - a.rawCreateTime),
    [CODE, apps, getTranslatedStatusLabel, parseAttendeeField, eventId],
  );

  const parseAppointmentRows = useCallback(
    (data) => {
      return data.map((item) => {
        const shouldShowCancelButton =
          !item.config.isCancelDisabled &&
          ((item.status === getStatusLabel(AppointmentStatus.PENDING) &&
            !isAttendeeOwnAppointment(item)) ||
            item.status === getStatusLabel(AppointmentStatus.CONFIRMED));

        return {
          id: item.id,
          rawEnd: item.rawEnd,
          content: (
            <GeneralListRow
              key={item.id}
              rowItems={[
                {
                  content: item.roomLink ? (
                    <IconButton
                      onClick={() => {
                        window.open(item.roomLink, '_blank');
                      }}
                    >
                      <RoomLinkIcon size={20} />
                    </IconButton>
                  ) : (
                    <></>
                  ),
                },
                {
                  content: (
                    <>
                      <StatusChip
                        label={item.translatedStatus}
                        rawstatus={item.status}
                      />
                    </>
                  ),
                  type: 'status',
                },
                {
                  content: (
                    <ListItemContainer>
                      <Typography variant="body2">
                        {item.dateTime.date}
                      </Typography>
                      <Typography variant="body2">
                        {item.dateTime.time}
                      </Typography>
                      <DateTimeZoneLabel>
                        {item.dateTime.zone}
                      </DateTimeZoneLabel>
                    </ListItemContainer>
                  ),
                },
                {
                  content: (
                    <Typography variant="body2">{item.createTime}</Typography>
                  ),
                },
                {
                  content: (
                    <ListItemContainer>
                      <PersonNameLabel>
                        {item.personToBeMet.name}
                      </PersonNameLabel>
                      <Typography
                        variant="body2"
                        className={classes.secondaryText}
                      >
                        {item.personToBeMet.company}
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.secondaryText}
                      >
                        {item.personToBeMet.title}
                      </Typography>
                    </ListItemContainer>
                  ),
                },
                {
                  content: (
                    <>
                      {shouldShowCancelButton && (
                        <CancelActionButton
                          disableElevation
                          variant="text"
                          onClick={() =>
                            handleConfirmationDialogOpen(
                              item.id,
                              ConfirmationType.CANCEL,
                            )
                          }
                        >
                          <StatusActionButtonLabel>
                            {item.status ===
                            getStatusLabel(AppointmentStatus.PENDING)
                              ? t('cancelInvitation')
                              : t('cancelBooking')}
                          </StatusActionButtonLabel>
                        </CancelActionButton>
                      )}

                      {item.status ===
                        getStatusLabel(AppointmentStatus.PENDING) &&
                        isAttendeeOwnAppointment(item) && (
                          <>
                            <StatusActionButton
                              disableElevation
                              variant="contained"
                              status="confirm"
                              onClick={() => {
                                handleAccept(item.id);
                              }}
                            >
                              <StatusActionButtonLabel status="confirm">
                                {t('confirmBtn')}
                              </StatusActionButtonLabel>
                            </StatusActionButton>
                            <StatusActionButton
                              disableElevation
                              variant="contained"
                              status="reject"
                              onClick={() =>
                                handleConfirmationDialogOpen(
                                  item.id,
                                  ConfirmationType.REJECT,
                                )
                              }
                            >
                              <StatusActionButtonLabel status="reject">
                                {t('rejectBtn')}
                              </StatusActionButtonLabel>
                            </StatusActionButton>
                          </>
                        )}
                    </>
                  ),
                  type: 'action',
                },
              ]}
            />
          ),
        };
      });
    },
    [
      classes.secondaryText,
      handleAccept,
      handleConfirmationDialogOpen,
      isAttendeeOwnAppointment,
      t,
    ],
  );

  const [lastSortingField, setLastSortingField] = useState(null);
  const [sortingConfig, setSortingConfig] = useState({
    rawStart: 'DESC',
    rawCreateTime: 'DESC',
  });

  const [statusFilter, setStatusFilter] = useState(
    getStatusLabel(AppointmentStatus.PENDING),
  );

  const [appointmentRows, setAppointmentRows] = useState(
    parseAppointmentRows(
      getAppointments().filter(
        (it) => it.status === getStatusLabel(AppointmentStatus.PENDING),
      ),
    ),
  );

  const handleSorting = useCallback(
    (field, status = null) => {
      const data =
        statusFilter || status
          ? getAppointments().filter(
              (it) => it.status === (status ?? statusFilter),
            )
          : getAppointments();

      const sortingOrder = sortingConfig[field];
      const sortedData = sortingConfig
        ? data.sort((a, b) => {
            if (sortingOrder === 'DESC') {
              return a[field] - b[field];
            }

            return b[field] - a[field];
          })
        : data;

      setAppointmentRows(parseAppointmentRows(sortedData));
      setSortingConfig({
        ...sortingConfig,
        [field]: sortingOrder === 'ASC' ? 'DESC' : 'ASC',
      });
      setLastSortingField(field);
    },
    [statusFilter, getAppointments, sortingConfig, parseAppointmentRows],
  );

  const handleStatusFilter = useCallback(
    (status) => {
      setSortingConfig({
        rawStart: 'DESC',
        rawCreateTime: 'DESC',
      });

      const data = status
        ? getAppointments().filter((it) => it.status === status)
        : getAppointments();

      setAppointmentRows(parseAppointmentRows(data));
      setStatusFilter(status);

      if (status === getStatusLabel(AppointmentStatus.CONFIRMED)) {
        setSortingConfig({
          rawStart: 'ASC',
          rawCreateTime: 'DESC',
        });
        handleSorting('rawStart', status);
      } else {
        setLastSortingField('rawCreateTime');
      }
    },
    [getAppointments, parseAppointmentRows, handleSorting],
  );

  const handleRefetch = useCallback(() => {
    const data = statusFilter
      ? getAppointments().filter((it) => it.status === statusFilter)
      : getAppointments();

    const sortingOrder = sortingConfig[lastSortingField];
    const sortedData = sortingConfig
      ? data.sort((a, b) => {
          if (sortingOrder === 'DESC') {
            return b[lastSortingField] - a[lastSortingField];
          }

          return a[lastSortingField] - b[lastSortingField];
        })
      : data;

    setAppointmentRows(parseAppointmentRows(sortedData));
  }, [
    getAppointments,
    lastSortingField,
    parseAppointmentRows,
    sortingConfig,
    statusFilter,
  ]);

  const statusFilterChips = useMemo(() => {
    return Object.keys(AppointmentStatus).map((key) => {
      if (key === AppointmentStatus.NORMAL) return;

      const label = getStatusLabel(key);
      const translatedLabel = getTranslatedStatusLabel(key);

      return (
        <StatusFilterChip
          key={key}
          status={statusFilter === label ? 'active' : ''}
          label={`${translatedLabel} (${parseAppointmentStatusesCount[label]})`}
          onClick={() => label !== statusFilter && handleStatusFilter(label)}
        />
      );
    });
  }, [
    getTranslatedStatusLabel,
    handleStatusFilter,
    parseAppointmentStatusesCount,
    statusFilter,
  ]);

  useEffect(() => {
    if (lastUpdated !== currentLastUpdated) {
      handleRefetch();
      setCurrentLastUpdated(lastUpdated);
    }
  }, [
    apps,
    currentLastUpdated,
    getAppointments,
    handleRefetch,
    lastUpdated,
    statusFilter,
  ]);

  if (isConfirmationLoading) {
    return (
      <div className="abs-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <StatusFilterContainer>{statusFilterChips}</StatusFilterContainer>

      {appointmentRows.length === 0 && <Divider />}

      {appointmentRows.length > 0 ? (
        <>
          <StyledContainer>
            <GeneralList
              CODE={CODE}
              columns={columns}
              rows={appointmentRows}
              sortingConfig={sortingConfig}
              handleSorting={handleSorting}
            />
          </StyledContainer>
        </>
      ) : (
        <NoAppointmentContainer>
          <img src={NoAppointment} width="175" />
          <NoAppointmentLabel>{t('noAppointment')}</NoAppointmentLabel>
        </NoAppointmentContainer>
      )}

      <Dialog
        open={open}
        onClose={handleConfirmationDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('confirmationDialogTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('confirmationDialogContent', {
              type:
                confirmationType === ConfirmationType.REJECT
                  ? t('rejectBtn')
                  : t('cancelLabelBtn'),
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <DialogCancelButton onClick={handleConfirmationDialogClose}>
            {t('cancelLabelBtn')}
          </DialogCancelButton>
          <DialogConfirmButton
            onClick={handleConfirmationDialogConfirm}
            color="primary"
            autoFocus
          >
            {t('confirmBtn')}
          </DialogConfirmButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default List;
